
<template>
    <div class="buttom">
        <div>
            免责声明：本站部分资讯来源于网络，如有侵权请及时联系客服，我们将尽快处理
        </div>

        <div>
            深圳市益汇投资控股有限公司版权所有 <a href="http://beian.miit.gov.cn">粤ICP备18146311号</a>
        </div>
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: "bottom",
    components: { router },
    data() {
        return {

        }
    },
    created() {

    },
    // 时间执行
    methods: {

    },
}
</script>

<style lang="less" scoped>
.buttom {
    width: 100%;
    height: 90px;
    background-color: #333333;
    overflow: hidden;
    div:nth-child(1){
        font-size: 12px;
        color: #f5f5f5;
        text-align: center;
        margin-top: 26px;
    }

    div:nth-child(2){
        font-size: 11px;
        color: #999999;
        text-align: center;
        margin-top: 13px;
        a{
            color: #999999;
        }
    }
}
</style>