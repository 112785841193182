import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'//映入elementui
// import VueWechatTitle from 'vue-wechat-title';
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/global.css'
// import '@/js/rem'
Vue.config.productionTip = false
// VueWechatTitle
Vue.use(ElementUI),//引入

  // router.beforeEach((to, form, next) => {
  //   console.log(document.title,to.meta.title);
  //   document.title = to.meta.title == ''? '益汇移民-国外留学': to.meta.title
  //   next()
  // })


  router.afterEach((to, from) => {
    if (document.title) {
      document.title = to.meta.title == '' ? '益汇移民-国外留学' : to.meta.title
    }else{
      document.title = '益汇移民-国外留学'
    }
    // document.title = to.meta.title == '' ? '益汇移民-国外留学' : to.meta.title
    // console.log(document.title,to.meta.title);
    // setTimeout(() => {
    //   document.title = to.meta.title == '' ? '益汇移民-国外留学' : to.meta.title
    // },0.5)
  })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
