<template>
    <div class="max">
        <!-- 跳转页面代码 -->
        <!-- <router-link to='/'>首页</router-link>
                                    <router-link to='/faxian'>发现</router-link> -->
        <div class="navigation ">
            <div class="navigationtest w">
                <div class="logs"><img src="@/images/logo1.png" alt=""></div>
                <div class="test">
                    <div class="test_nr">
                        <h2>向全球高净值人士提供全面的综合资产管理及海外移民服务</h2>
                        <h2>Provide comprehensive comprehensive asset management and overseas migration services to
                            the global high-net-worth people</h2>
                    </div>
                </div>

                <div class="conversation">
                    <div class="conversation_img">
                        <img src="@/images/dianhua.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="secondary">
            <div class="w secondary_tes">
                <div class="homes" @click.stop="shouye('#/')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/' ? '' : 'imagses'">
                    <router-link to="/" class="asno">
                        <p :class="route == '#/' ? 'attribute' : ''">首页 </p>
                    </router-link>
                </div>

                 <!-- 快速护照 -->
                 <!-- <div class="kuaisuhuzhao" @click.stop="shouye('#/Kitts')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/Kitts' ? '' : 'imagses'">

                 
                    <router-link :to="{ name: 'Kitts' }" class="asno">
                        <p :class="route == '#/Kitts' ? 'attribute' : ''">快速护照</p>
                    </router-link>

                    <div class="float_bottom_kuaisu" >
                        <div class="bot_bottom w">
                            <div class="bot_bottom_left">
                                <ul>
                                    <li>
                                        中国香港身份
                                    </li>

                                    <li>
                                        <router-link to="/skilled">香港高才计划</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/talents">香港优才计划</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/specialists">香港专才计划</router-link>
                                    </li>

                                    <li @click.stop="shouye('#/hongKong')">
                                        <router-link to="/hongKong">香港进修移民</router-link>
                                    </li>
                                </ul>

                              
                            </div>

                            <div class="bot_bottom_img">
                                <ul>
                                    <li>
                                        <router-link to="/specialists">
                                            <img class="imgnos" src="@/images/bj10_a.f0d13a1f.jpg" alt="">
                                            <p>香港专才移民</p>
                                        </router-link>
                                    </li>

                                    <li style="margin-top: 20px;">
                                        <router-link to="/talents">
                                            <img class="imgnos" src="@/images/bj10_aa.jpg" alt="">
                                            <p>香港优才移民</p>
                                        </router-link>
                                    </li>
                                </ul>


                                <ul>
                                    <li>
                                        <router-link to="/skilled">
                                            <img class="imgnos" src="@/images/gaocai.jpg" alt="">
                                            <p>香港高才移民</p>
                                        </router-link>
                                    </li>

                                    <li style="margin-top: 20px;">
                                        <router-link to="/hongKong">
                                            <img class="imgnos" src="@/images/jinxiu.jpg" alt="">
                                            <p>香港进修留学</p>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->


                <!-- 亚洲移民 -->
                <div class="country" @click.stop="shouye('#/skilled')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/skilled' ? '' : 'imagses'">

                    <!-- <router-link :to="{ name: 'country' }" class="asno">
                        <p :class="route == '#/country' ? 'attribute' : ''">香港移民</p>
                    </router-link> -->

                    <router-link :to="{ name: 'skilled' }" class="asno">
                        <p :class="route == '#/skilled' ? 'attribute' : ''">香港移民</p>
                    </router-link>

                    <div class="float_bottom" >
                        <div class="bot_bottom w">
                            <div class="bot_bottom_left">
                                <ul>
                                    <li>
                                        中国香港身份
                                    </li>

                                    <li>
                                        <router-link to="/skilled">香港高才计划</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/talents">香港优才计划</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/specialists">香港专才计划</router-link>
                                    </li>

                                    <li @click.stop="shouye('#/hongKong')">
                                        <router-link to="/hongKong">香港进修移民</router-link>
                                    </li>
                                </ul>

                                <!-- <ul>
                                    <li>
                                        新加坡
                                    </li>

                                    <li>
                                        <router-link to="/singapore">新加坡雇主担保移民</router-link>
                                    </li>
                                </ul> -->

                                <!-- <ul>
                                    <li>
                                        韩国
                                    </li>

                                    <li>
                                        <router-link to="/han">韩国投资移民</router-link>
                                    </li>
                                </ul> -->
                            </div>

                            <!-- <div class="bot_bottom_right">
                                <ul>
                                    <li>
                                        日本
                                    </li>

                                    <li>
                                        <router-link to="/japan">日本经营者签证</router-link>
                                    </li>
                                </ul>

                                <ul>
                                    <li>
                                        土耳其
                                    </li>

                                    <li>
                                        <router-link to="/turkiye">土耳其购房移民</router-link>
                                    </li>
                                </ul>
                            </div>

                            <div class="bot_bottom_cet">
                                <ul>
                                    <li>
                                        <router-link to="/passport">移民护照</router-link>
                                    </li>

                                    <li>
                                        <router-link target="_blank"
                                            :to="{ name: 'passport', query: { id: '#way' } }">投资护照方式</router-link>
                                    </li>

                                    <li>
                                        <router-link target="_blank"
                                            :to="{ name: 'passport', query: { id: '#invest' } }">投资护照地区</router-link>
                                    </li>

                                    <li>
                                        <router-link target="_blank"
                                            :to="{ name: 'passport', query: { id: '#country' } }">投资房产</router-link>
                                    </li>
                                </ul>
                            </div> -->

                            <div class="bot_bottom_img">
                                <ul>
                                    <li>
                                        <router-link to="/specialists">
                                            <img class="imgnos" src="@/images/bj10_a.f0d13a1f.jpg" alt="">
                                            <p>香港专才移民</p>
                                        </router-link>
                                    </li>

                                    <li style="margin-top: 20px;">
                                        <router-link to="/talents">
                                            <img class="imgnos" src="@/images/bj10_aa.jpg" alt="">
                                            <p>香港优才移民</p>
                                        </router-link>
                                    </li>
                                </ul>


                                <ul>
                                    <li>
                                        <router-link to="/skilled">
                                            <img class="imgnos" src="@/images/gaocai.jpg" alt="">
                                            <p>香港高才移民</p>
                                        </router-link>
                                    </li>

                                    <li style="margin-top: 20px;">
                                        <router-link to="/hongKong">
                                            <img class="imgnos" src="@/images/jinxiu.jpg" alt="">
                                            <p>香港进修留学</p>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


               <!-- 移民三大板块 -->

               <!--  -->

                  <div class="passport" @click.stop="shouye('#/passport')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/passport' ? '' : 'imagses'">

                    <router-link to="../passport" class="asno">
                        <p :class="route == '#/passport' ? 'attribute' : ''">快速护照</p>
                    </router-link>
                </div>

                <!-- 香港留学 -->
                <div class="hongKong" @click.stop="shouye('#/hongKong')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/hongKong' ? '' : 'imagses'">

                    <router-link :to="{ name: 'hongKonghome' }" class="asno">
                        <p :class="route == '#/hongKong' ? 'attribute' : ''">留学进修</p>
                    </router-link>

                    <div class="float_bottomxianggliuxue" >
                        <div class="bot_bottom w">
                            <div class="bot_bottom_left">
                                <ul>
                                    <li>
                                        <router-link to="/hongKong">香港留学</router-link>
                                    </li>

                                    <li><router-link :to="{ name: 'text3' }">香港大学</router-link></li>
                                    <li><router-link :to="{ name: 'text4' }">香港科技大学</router-link></li>
                                    <li><router-link :to="{ name: 'text5' }">香港中文大学</router-link></li>
                                    <li><router-link :to="{ name: 'text6' }">香港理工大学</router-link></li>
                                    <li><router-link :to="{ name: 'text7' }">香港浸会大学</router-link></li>
                                    <li><router-link :to="{ name: 'text10' }">香港城市大学</router-link></li>
                                    <li><router-link :to="{ name: 'text11' }">香港教育大学</router-link></li>
                                    <li><router-link :to="{ name: 'text12' }">香港岭南大学</router-link></li>
                                </ul>

                            </div>

                            <div class="bot_bottom_right">
                                <ul>
                                    <li>
                                        香港国际学校
                                    </li>
                                    <li><router-link :to="{ name: 'text1' }">宣道国际学校</router-link></li>
                                    <li><router-link :to="{ name: 'text2' }">香港基督教国际学校</router-link></li>


                                </ul>

                                <!-- <ul>
                                    <li>
                                        土耳其
                                    </li>

                                    <li>
                                        <router-link to="/turkiye">土耳其购房移民</router-link>
                                    </li>
                                </ul> -->
                            </div>

                            <div class="bot_bottom_cet">
                                <ul>
                                    <li>
                                        <router-link to="/overseas">出国留学</router-link>
                                    </li>

                                    <li>
                                        <router-link
                                            :to="{ name: 'overseas', query: { id: '#country' } }">热门留学国家</router-link>
                                    </li>

                                    <li>
                                        <router-link :to="{ name: 'overseas', query: { id: '#way' } }">热门留学方式</router-link>
                                    </li>

                                    <li>
                                        <router-link
                                            :to="{ name: 'overseas', query: { id: '#success' } }">部分成功案例</router-link>
                                    </li>

                                    <li>
                                        <router-link :to="{ name: 'overseas', query: { id: '#study' } }">留学资讯</router-link>
                                    </li>
                                </ul>
                            </div>

                            <div class="bot_bottom_img">
                                <ul>
                                    <li>
                                        <router-link :to="{ name: 'text8' }">
                                            <img class="imgnos" src="@/images/sq.jpg" alt="">
                                            <p>香港本科申请要求</p>
                                        </router-link>
                                    </li>

                                    <li style="margin-top: 20px;">
                                        <router-link :to="{ name: 'text9' }">
                                            <img class="imgnos" src="@/images/zy.jpg" alt="">
                                            <p>2023香港本科申请已开启</p>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>




                <!-- 其他国家移民 -->
                <div class="country" @click.stop="shouye('#/country')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/country' ? '' : 'imagses'">

                    <router-link :to="{ name: 'country' }" class="asno">
                        <p :class="route == '#/country' ? 'attribute' : ''">海外移民</p>
                    </router-link>

                    <!-- <router-link :to="{ name: 'talents' }" class="asno">
                        <p :class="route == '#/talents' ? 'attribute' : ''">香港移民</p>
                    </router-link> -->

                    <div class="float_bottomouzhou" >
                        <div class="bot_bottom w">
                            <div class="bot_bottom_left">
                                <ul>
                                    <li>
                                        亚洲移民
                                    </li>

                                    <li>
                                        <router-link to="/singapore">新加坡雇主担保移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/han">韩国投资移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/japan">日本经营管理签证</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/turkiye">土耳其购房移民</router-link>
                                    </li>
                                </ul>

                                

                                <!-- <ul>
                                    <li>
                                        新加坡
                                    </li>

                                    <li>
                                        <router-link to="/singapore">新加坡雇主担保移民</router-link>
                                    </li>
                                </ul> -->

                                <!-- <ul>
                                    <li>
                                        韩国
                                    </li>

                                    <li>
                                        <router-link to="/han">韩国投资移民</router-link>
                                    </li>
                                </ul> -->
                            </div>

                            <div class="bot_bottom_right">
                                <ul>
                                    <li>
                                        <router-link to="/europe">欧洲移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/turkiye">土耳其购房移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/cyprus">塞浦路斯移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/malta">马耳他移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/moldova">摩尔多瓦移民</router-link>
                                    </li>
                                </ul>
                            </div>

                            <div class="bot_bottom_cet">
                                <ul>
                                    <li>
                                        <router-link to="/caribbean">美洲移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/americahome">美国杰出人才移民EB-1A</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/grenada">格林纳达护照移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/dominica">多米尼克移民</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/Kitts">圣基茨投资入籍</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/antigua">安提瓜·巴布达移民</router-link>
                                    </li>
                                </ul>
                            </div>


                            <div class="bot_bottom_cet">
                                <ul>
                                    <li>
                                        大洋州移民
                                    </li>

                                    <li>
                                        <router-link to="/aozou">澳洲188A签证</router-link>
                                    </li>

                                    <li>
                                        <router-link to="/vanuatu">瓦努阿图护照移民</router-link>
                                    </li>
                                  
                                </ul>
                            </div>

                            <!-- <div class="bot_bottom_right">
                                <ul>
                                    <li>
                                        日本
                                    </li>

                                    <li>
                                        <router-link to="/japan">日本经营者签证</router-link>
                                    </li>
                                </ul>

                                <ul>
                                    <li>
                                        土耳其
                                    </li>

                                    <li>
                                        <router-link to="/turkiye">土耳其购房移民</router-link>
                                    </li>
                                </ul>
                            </div>

                            <div class="bot_bottom_cet">
                                <ul>
                                    <li>
                                        <router-link to="/passport">移民护照</router-link>
                                    </li>

                                    <li>
                                        <router-link target="_blank"
                                            :to="{ name: 'passport', query: { id: '#way' } }">投资护照方式</router-link>
                                    </li>

                                    <li>
                                        <router-link target="_blank"
                                            :to="{ name: 'passport', query: { id: '#invest' } }">投资护照地区</router-link>
                                    </li>

                                    <li>
                                        <router-link target="_blank"
                                            :to="{ name: 'passport', query: { id: '#country' } }">投资房产</router-link>
                                    </li>
                                </ul>
                            </div> -->

                            <!-- <div class="bot_bottom_img">
                                <ul>
                                    <li>
                                        <router-link to="/specialists">
                                            <img class="imgnos" src="@/images/bj10_a.jpg" alt="">
                                            <p>香港专才移民</p>
                                        </router-link>
                                    </li>

                                    <li style="margin-top: 20px;">
                                        <router-link to="/talents">
                                            <img class="imgnos" src="@/images/bj10_aa.jpg" alt="">
                                            <p>香港优才移民</p>
                                        </router-link>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>

              


                <!-- <div class="overseas" @click.stop="shouye('#/overseas')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/overseas' ? '' : 'imagses'">

                    <router-link to="../overseas" class="asno">
                        <p :class="route == '#/overseas' ? 'attribute' : ''">出国留学</p>
                    </router-link>
                </div> -->

                <div class="case" @click.stop="shouye('#/success')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/success' ? '' : 'imagses'">

                    <router-link to="../success" class="asno">
                        <p :class="route == '#/success' ? 'attribute' : ''">成功案例</p>
                    </router-link>
                </div>

                <div class="life" @click.stop="shouye('#/life')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/life' ? '' : 'imagses'">


                    <router-link to="../life" class="asno">
                        <p :class="route == '#/life' ? 'attribute' : ''">移民资讯</p>
                    </router-link>

                    <!-- <div class="minhovers">
                        <ul>
                            <li><router-link :to="{ name: 'life' }">海外生活</router-link></li>
                        </ul>
                    </div> -->
                </div>

               
                <!-- 合伙人 -->
                <div class="partner" @click.stop="shouye('#/partner')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/partner' ? '' : 'imagses'">

                    <router-link to="../partner" class="asno">
                        <p :class="route == '#/partner' ? 'attribute' : ''">加盟公司</p>
                    </router-link>
                    
                </div>

                <!-- 关于益汇 -->
                <div class="Introduction" @click.stop="shouye('#/Introduction')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/Introduction' ? '' : 'imagses'">


                    <router-link to="../about" class="asno">
                        <p :class="route == '#/Introduction' ? 'attribute' : ''">关于益汇</p>
                    </router-link>

                    <div class="minhovers">
                        <ul>
                            <li><router-link :to="{ name: 'about' }">关于益汇</router-link></li>
                            <li><router-link :to="{ name: 'Introduction' }">移民政策</router-link></li>
                            <li><router-link :to="{ name: 'contact' }">联系我们</router-link></li>
                        </ul>
                    </div>
                </div>


                <!-- <div class="contact" @click.stop="shouye('#/contact')">
                    <img src="@/images/di.jpg" alt="" :class="route == '#/contact' ? '' : 'imagses'">


                    <router-link to="../contact" class="asno">
                        <p :class="route == '#/contact' ? 'attribute' : ''">联系我们</p>
                    </router-link>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: "head",
    components: { router },
    data() {
        return {
            // 当前路径
            route: [],
        }
    },
    mounted() {
        // window.addEventListener('scroll', () => {
        //     console.log(333);
        //   // 计算当前滚动距离scrollTop
        //   const scrollTop = (document.documentElement.scrollTop || document.body.scrollTop) + 200;

        //   // 根据需要调整top值
        //   element.style.top = scrollTop + 'px';
        // });
    },
    created() {
        this.route = window.location.hash
        console.log(this.route);

    },
    // 时间执行
    methods: {
        shouye(key) {
            // console.log(document);
            this.route = key
        }
    },
}
</script>

<style lang="less" scoped>
.w {
    width: 1256px;
    height: 100%;
    margin: 0 auto;
    // background-color: aqua;
}

.attribute {
    // transition: all 0.3s linear 0s;
    color: #a67e3d !important;
    background-color: #fff;
}

// tupian
.imagses {
    display: none;
}

.max {
    // overflow: hidden;

    .navigation {
        width: 100%;
        height: 150px;
        background-color: #232a47;

        .navigationtest {
            display: flex;
            justify-content: space-between;

            .logs {
                display: flex;

                img {
                    margin: auto;
                    // line-height: 200px;
                    width: 229px;
                    height: 81px;
                }
            }

            .test {
                width: 581px;
                overflow: hidden;
                display: flex;

                .test_nr {
                    margin: auto 0;

                    h2:nth-child(1) {
                        font-size: 22px;
                        color: #d0d0d0;
                    }

                    h2:nth-child(2) {
                        font-size: 12px;
                        color: #d0d0d0;
                        margin-top: 8px;
                        line-height: 17px;
                    }
                }
            }

            .conversation {
                display: flex;

                .conversation_img {
                    margin: auto 0;
                }
            }
        }
    }

    .secondary {
        width: 100%;
        height: 50px;
        background-color: #9c8979;

        .secondary_tes {
            display: flex;
            justify-content: center;

            img {
                position: absolute;
                top: 47px;
                left: 48px;
            }

            .homes,
            .country,
            .passport,
            .hongKong,
            .overseas,
            .case,
            .life,
            .Introduction,
            .partner,
            .europe,
            .caribbean,
            .aozou,
            .contact {
                flex: 1;
                text-align: center;
                color: #ffffff;
                font-size: 12px;
                position: relative;

                .asno {
                    width: 100%;
                    height: 100%;
                    display: block;
                    color: #ffffff !important;

                    p {
                        line-height: 50px;
                    }
                }

                .asno:hover {
                    transition: all 0.3s linear 0s;
                    color: #9c8979 !important;
                    background-color: #fff;
                }
                
                // 611px
                .float_bottom {
                    width: 1917px;
                    // padding: 20px 0;
                    background-color: #f4f3e8;
                    position: absolute;
                    left: -471px;
                    z-index: 1;
                    display: none;
                    // display: block;
                    .bot_bottom {
                        padding: 40px 0px;
                        display: flex;
                        background-color: #f4f3e8;
                        .bot_bottom_left,
                        .bot_bottom_right,
                        .bot_bottom_cet {
                            ul {
                                text-align: left;

                                li:nth-child(1) {
                                    color: 333333;
                                    font-size: 16px;
                                    border-left: 2px solid #be9d67;
                                    padding-left: 10px;
                                }

                                li {
                                    color: #725533;
                                    font-size: 14px;
                                    padding-left: 12px;

                                    a {
                                        color: #725533 !important;
                                        display: block;
                                    }

                                    a:hover {
                                        color: #d7a85e !important;
                                    }
                                }

                                li:nth-child(n + 2) {
                                    margin-top: 10px;
                                }
                            }

                            ul:nth-child(2) {
                                margin-top: 20px;
                            }

                            ul:nth-child(3) {
                                margin-top: 20px;
                            }
                        }

                        .bot_bottom_right {
                            margin-left: 120px;
                        }

                        .bot_bottom_cet {
                            margin-left: 120px;
                        }

                        .bot_bottom_img {
                            margin-left: 150px;
                            border-left: 2px solid #d0b789;
                            padding-left: 50px;
                            display: flex;

                            .imgnos {
                                position: relative;
                                width: 198px;
                                top: 0;
                                left: 0;
                            }

                            p {
                                text-align: center;
                                color: #725533 !important;
                            }

                            ul:nth-child(2) {
                                margin-left: 20px;
                            }
                        }
                    }
                }
               
             

                .float_bottomouzhou {
                    width: 1917px;
                    // padding: 20px 0;
                    background-color: #f4f3e8;
                    position: absolute;
                    left: -890px;
                    z-index: 1;
                    display: none;
                    // display: block;
                    .bot_bottom {
                        padding: 40px 0px;
                        display: flex;
                        background-color: #f4f3e8;
                        .bot_bottom_left,
                        .bot_bottom_right,
                        .bot_bottom_cet {
                            ul {
                                text-align: left;

                                li:nth-child(1) {
                                    color: 333333;
                                    font-size: 16px;
                                    border-left: 2px solid #be9d67;
                                    padding-left: 10px;
                                }

                                li {
                                    color: #725533;
                                    font-size: 14px;
                                    padding-left: 12px;

                                    a {
                                        color: #725533 !important;
                                        display: block;
                                    }

                                    a:hover {
                                        color: #d7a85e !important;
                                    }
                                }

                                li:nth-child(n + 2) {
                                    margin-top: 10px;
                                }
                            }

                            ul:nth-child(2) {
                                margin-top: 20px;
                            }

                            ul:nth-child(3) {
                                margin-top: 20px;
                            }
                        }

                        .bot_bottom_right {
                            margin-left: 120px;
                        }

                        .bot_bottom_cet {
                            margin-left: 120px;
                        }

                        .bot_bottom_img {
                            margin-left: 150px;
                            border-left: 2px solid #d0b789;
                            padding-left: 50px;
                            display: flex;

                            .imgnos {
                                position: relative;
                                width: 198px;
                                top: 0;
                                left: 0;
                            }

                            p {
                                text-align: center;
                                color: #725533 !important;
                            }

                            ul:nth-child(2) {
                                margin-left: 20px;
                            }
                        }
                    }
                }

                .float_bottommeizhou {
                    width: 1917px;
                    // padding: 20px 0;
                    background-color: #f4f3e8;
                    position: absolute;
                    left: -708px;
                    z-index: 1;
                    display: none;
                    // display: block;
                    .bot_bottom {
                        padding: 40px 0px;
                        display: flex;
                        background-color: #f4f3e8;
                        .bot_bottom_left,
                        .bot_bottom_right,
                        .bot_bottom_cet {
                            ul {
                                text-align: left;

                                li:nth-child(1) {
                                    color: 333333;
                                    font-size: 16px;
                                    border-left: 2px solid #be9d67;
                                    padding-left: 10px;
                                }

                                li {
                                    color: #725533;
                                    font-size: 14px;
                                    padding-left: 12px;

                                    a {
                                        color: #725533 !important;
                                        display: block;
                                    }

                                    a:hover {
                                        color: #d7a85e !important;
                                    }
                                }

                                li:nth-child(n + 2) {
                                    margin-top: 10px;
                                }
                            }

                            ul:nth-child(2) {
                                margin-top: 20px;
                            }

                            ul:nth-child(3) {
                                margin-top: 20px;
                            }
                        }

                        .bot_bottom_right {
                            margin-left: 120px;
                        }

                        .bot_bottom_cet {
                            margin-left: 120px;
                        }

                        .bot_bottom_img {
                            margin-left: 150px;
                            border-left: 2px solid #d0b789;
                            padding-left: 50px;
                            display: flex;

                            .imgnos {
                                position: relative;
                                width: 198px;
                                top: 0;
                                left: 0;
                            }

                            p {
                                text-align: center;
                                color: #725533 !important;
                            }

                            ul:nth-child(2) {
                                margin-left: 20px;
                            }
                        }
                    }
                }

                .float_bottomdayangzou {
                    width: 1917px;
                    // padding: 20px 0;
                    background-color: #f4f3e8;
                    position: absolute;
                    left: -834px;
                    z-index: 1;
                    display: none;
                    // display: block;
                    .bot_bottom {
                        padding: 40px 0px;
                        display: flex;
                        background-color: #f4f3e8;
                        .bot_bottom_left,
                        .bot_bottom_right,
                        .bot_bottom_cet {
                            ul {
                                text-align: left;

                                li:nth-child(1) {
                                    color: 333333;
                                    font-size: 16px;
                                    border-left: 2px solid #be9d67;
                                    padding-left: 10px;
                                }

                                li {
                                    color: #725533;
                                    font-size: 14px;
                                    padding-left: 12px;

                                    a {
                                        color: #725533 !important;
                                        display: block;
                                    }

                                    a:hover {
                                        color: #d7a85e !important;
                                    }
                                }

                                li:nth-child(n + 2) {
                                    margin-top: 10px;
                                }
                            }

                            ul:nth-child(2) {
                                margin-top: 20px;
                            }

                            ul:nth-child(3) {
                                margin-top: 20px;
                            }
                        }

                        .bot_bottom_right {
                            margin-left: 120px;
                        }

                        .bot_bottom_cet {
                            margin-left: 120px;
                        }

                        .bot_bottom_img {
                            margin-left: 150px;
                            border-left: 2px solid #d0b789;
                            padding-left: 50px;
                            display: flex;

                            .imgnos {
                                position: relative;
                                width: 198px;
                                top: 0;
                                left: 0;
                            }

                            p {
                                text-align: center;
                                color: #725533 !important;
                            }

                            ul:nth-child(2) {
                                margin-left: 20px;
                            }
                        }
                    }
                }

                .float_bottomxianggliuxue {
                    width: 1917px;
                    // padding: 20px 0;
                    background-color: #f4f3e8;
                    position: absolute;
                    left: -750px;
                    z-index: 1;
                    display: none;
                    // display: block;
                    .bot_bottom {
                        padding: 40px 0px;
                        display: flex;
                        background-color: #f4f3e8;
                        .bot_bottom_left,
                        .bot_bottom_right,
                        .bot_bottom_cet {
                            ul {
                                text-align: left;

                                li:nth-child(1) {
                                    color: 333333;
                                    font-size: 16px;
                                    border-left: 2px solid #be9d67;
                                    padding-left: 10px;
                                }

                                li {
                                    color: #725533;
                                    font-size: 14px;
                                    padding-left: 12px;

                                    a {
                                        color: #725533 !important;
                                        display: block;
                                    }

                                    a:hover {
                                        color: #d7a85e !important;
                                    }
                                }

                                li:nth-child(n + 2) {
                                    margin-top: 10px;
                                }
                            }

                            ul:nth-child(2) {
                                margin-top: 20px;
                            }

                            ul:nth-child(3) {
                                margin-top: 20px;
                            }
                        }

                        .bot_bottom_right {
                            margin-left: 120px;
                        }

                        .bot_bottom_cet {
                            margin-left: 120px;
                        }

                        .bot_bottom_img {
                            margin-left: 150px;
                            border-left: 2px solid #d0b789;
                            padding-left: 50px;
                            display: flex;

                            .imgnos {
                                position: relative;
                                width: 198px;
                                top: 0;
                                left: 0;
                            }

                            p {
                                text-align: center;
                                color: #725533 !important;
                            }

                            ul:nth-child(2) {
                                margin-left: 20px;
                            }
                        }
                    }
                }

                .minhovers {
                    display: none;

                    ul {
                        padding: 0 0 20px 0;
                        background-color: #f4f3e8;

                        li {
                            padding-top: 20px;
                            font-size: 14px;

                            a {
                                color: #725533;
                            }

                            a:hover {
                                color: #a67e3d;
                            }
                        }
                    }
                }

            }

            div:hover {

                .float_bottom,
                .float_bottomouzhou,
                .float_bottommeizhou,
                .float_bottomdayangzou,
                .float_bottomxianggliuxue,
                .minhovers {
                    display: block;
                }
            }
        }
    }
}</style>