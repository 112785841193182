import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',

    component: () => import('@/views/home.vue'),
    meta: {
      title: '深圳投资顾问移民中介-深圳海外出国留学移民机构-益汇集团',
      keywords:'深圳移民公司,移民中介,投资移民,留学机构',
      description:'益汇集团总部2014年创立于香港，2018年在深圳前海设立大中华区运营总部，2019年在广州、宁波等地设立多个分支机构，益汇集团与全球各大知名机构合作，以国际化最先进的服务理念向全球高净值人士提供海外移民、出国留学、资产管理等各种增值服务。'
    }
  },
  {
    path: '/country',
    name: 'country',
    component: () => import('@/views/country.vue'),
    meta: {
      title: '海外投资技术买房护照移民出国留学-移民国家条件-益汇集团',
      keywords:'投资移民，海外移民，投资移民，香港移民',
      description:'益汇集团总部2014年创立于香港，2018年在深圳前海设立大中华区运营总部，2019年在广州、宁波等地设立多个分支机构，益汇集团与全球各大知名机构合作，以国际化最先进的服务理念向全球高净值人士提供投资移民，海外移民，投资移民，出国留学、资产管理等各种增值服务。'
    }

  },

  {
    path: '/partner',
    name: 'partner',
    component: () => import('@/views/partner.vue'),
    meta: {
      title: '海外投资技术买房护照移民出国留学-移民国家条件-益汇集团',
      keywords:'投资移民，海外移民，投资移民，香港移民',
      description:'益汇集团总部2014年创立于香港，2018年在深圳前海设立大中华区运营总部，2019年在广州、宁波等地设立多个分支机构，益汇集团与全球各大知名机构合作，以国际化最先进的服务理念向全球高净值人士提供投资移民，海外移民，投资移民，出国留学、资产管理等各种增值服务。'
    }

  },

  {
    path: '/passport',
    name: 'passport',
    component: () => import('@/views/passport.vue'),
    meta: {
      title: '移民护照'
    }

  },

  {
    path: '/hongKong',
    name: 'hongKonghome',
    component: () => import('@/views/hongKong.vue'),
    meta: {
      title: '香港留学留学进修香港保录香港大学-香港留学-进修-香港移民-益汇移民'
    }

  },
  {
    path: '/overseas',
    name: 'overseas',
    component: () => import('@/views/overseas.vue'),
    meta: {
      title: '出国留学'
    }

  },

  {
    path: '/success',
    name: 'success',
    component: () => import('@/views/success.vue'),
    meta: {
      title: '成功案例成功移民案例成功留学案例美国移民-香港移民-全球出行移民-益汇移民-成功案例'
    }

  },
  {
    path: '/life',
    name: 'life',
    component: () => import('@/views/life.vue'),
    meta: {
      title: '海外生活资讯信息移民资讯交流社区移民交流-益汇移民'
    }

  },

  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue'),
    meta: {
      title: '关于益汇关于我们公司简介企业文化业务来往-关于益汇'
    }

  },
  {
    path: '/Introduction',
    name: 'Introduction',
    component: () => import('@/views/Introduction.vue'),
    meta: {
      title: '政策解读移民政策移民国家-亚洲移民-欧洲移民-北美洲移民-大洋洲移民-益汇移民'
    }

  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact.vue'),
    meta: {
      title: '联系我们'
    }


  },
  {
    path: '/talents',
    name: 'talents',
    component: () => import('@/views/project/talents.vue'),
    meta: {
      title: '香港优才计划申请条件政策-优才计划移民评分中介-益汇集团',
      keywords:'香港优才移民，香港优秀人才入境计划,香港移民',
      description:'香港优秀人才入境计划（Quality Migrant Admission Scheme）简称优才计划，是香港特区政府于2006年6月正式推行的一项人才引进计划。宗旨是吸纳中国内地以及外国高技术、高学历人才来港定居，以此来提升香港的国际竞争力。申请人须首先符合基本资格的要求，后据计划（综合计分制）或（成就计分制）中的其中一套获取分数、与其他申请人竞争配额。',
    }
  },

  {
    path: '/skilled',
    name: 'skilled',
    component: () => import('@/views/project/skilled.vue'),
    meta: {
      title: '香港优才计划申请条件政策-优才计划移民评分中介-益汇集团',
      keywords:'香港优才移民，香港优秀人才入境计划,香港移民',
      description:'香港优秀人才入境计划（Quality Migrant Admission Scheme）简称优才计划，是香港特区政府于2006年6月正式推行的一项人才引进计划。宗旨是吸纳中国内地以及外国高技术、高学历人才来港定居，以此来提升香港的国际竞争力。申请人须首先符合基本资格的要求，后据计划（综合计分制）或（成就计分制）中的其中一套获取分数、与其他申请人竞争配额。',
    }
  },
  {
    path: '/Kitts',
    name: 'Kitts',
    component: () => import('@/views/project/Kitts.vue'),
    meta: {
      title: '圣基茨快速护照项目-投资移民-投资护照-益汇集团',
      keywords:'圣基茨快速护照，快速移民国外',
    }

  }, {
    path: '/specialists',
    name: 'specialists',
    component: () => import('@/views/project/specialists.vue'),
    meta: {
      title: '香港专才移民申请资格条件政策-专才计划流程要求-益汇集团',
      keywords:'香港专才移民，香港移民，香港输入内地人才计划',
      description:'香港专才，全称“中国香港输入内地人才计划”，是面向内地优秀人才和专业人才的一项人才计划。该计划凭借着没有配额限制、通过率高以及门槛低等优势，与香港优才计划一同成为香港目前为热门的人才引进计划。',
    }
  },

  , {
    path: '/singapore',
    name: 'singapore',
    component: () => import('@/views/project/singapore.vue'),
    meta: {
      title: '新加坡pic自雇移民条件政策-ep移民合伙人申请费用-益汇集团',
      keywords:'新加坡自雇移民，新加坡ep移民，新加坡PIC自雇移民，新加坡移民',
      description:'新加坡自雇移民指申请人自己在新加坡经营企业或投资入股现有企业后，以企业高管加股东的身份申请SP/EP工作准证，持有SP/EP工作准证36个月且企业正常运行，申请人向新加坡移民局申请PR永久居民身份的移民方式。',
    }

  }, {
    path: '/americahome',
    name: 'americahome',
    component: () => import('@/views/project/america.vue')

  }, {
    path: '/grenada',
    name: 'grenada',
    component: () => import('@/views/project/grenada.vue')

  },

  {
    path: '/han',
    name: 'han',
    component: () => import('@/views/project/han.vue'),
    meta: {
      title: '韩国投资移民签证条件政策-投资移民房产项目费用-益汇集团',
      keywords:'韩国投资移民，韩国投资移民政策，韩国移民，韩国投资移民签证',
      description:'韩国移民方式称为存款移民，也叫做公益事业投资移民。存款3亿/5亿/15亿韩币在韩国政府银行（可结合房产投资），其中F2阶段五年后获得韩国F5永居身份，获得永居后取回存款，永居身份不受影响。 申请条件宽松，可携带超龄子女，无任何居住要求，快至6个工作日拿身份。 投资灵活，存款期间无管理费用，可随时取回存款。',
    }

  },
  {
    path: '/vanuatu',
    name: 'vanuatu',
    component: () => import('@/views/project/vanuatu.vue'),
    meta: {
      title: '瓦努阿图永居移民 - 益汇移民 - 移民 - 新加坡',
    }


  },
  {
    path: '/aozou',
    name: 'aozou',
    component: () => import('@/views/project/aozou.vue'),
    meta: {
      title: '海外投资技术买房护照移民出国留学-移民国家条件-大洋洲移民-益汇集团',
    }
  },
  {
    path: '/cyprus',
    name: 'cyprus',
    component: () => import('@/views/project/cyprus.vue'),
    meta:{
      title: '塞浦路斯移民-移民国家条件-欧洲移民-益汇集团',
    }
  },

  {
    path: '/malta',
    name: 'malta',
    component: () => import('@/views/project/malta.vue'),
    meta:{
      title: '马耳他投资移民条件政策优势-马耳他买房移民中介费用-益汇集团',
      keywords:'马耳他移民绿卡，马耳他投资移民，马耳他移民中介，马耳他买房移民',
      description:'马耳他是欧盟成员国和申根区成员国，推出的马耳他永久居留计划(MPRP)允许第三国国民在马耳他获得居留权，该计划受强大的立法框架管辖，允许主申请人与他或她的配偶、子女、父母(包括姻亲父母)和祖父母等家庭成员共同申请，并将获得在马耳他无限期居留或定居的权利。',
    }
  },
  {
    path: '/dominica',
    name: 'dominica',
    component: () => import('@/views/project/dominica.vue')
  },
  {
    path: '/moldova',
    name: 'moldova',
    component: () => import('@/views/project/moldova.vue'),
    meta:{
      title: '摩尔多瓦移民-移民国家条件-欧洲移民-益汇集团',
    }
  },
  {
    path: '/antigua',
    name: 'antigua',
    component: () => import('@/views/project/antigua.vue')
  },
  {
    path: '/turkiye',
    name: 'turkiye',
    component: () => import('@/views/project/turkiye.vue'),
    meta:{
      title: '土耳其购房移民优惠政策-土耳其购房移民费用优势-益汇集团',
      keywords:'土耳其购房移民，土耳其移民，土耳其买房，土耳其购房',
      description:'土耳其购房移民项目的火热程度始于2018年。在此之前，土耳其购房移民项目的申请条件相对宽松，但是2018年后，土耳其政府开始收紧政策，加强了申请条件和审核流程，使得申请人需要更高的资质和投资额才能符合条件。然而，尽管政策收紧，土耳其仍然是吸引国际移民的热门目的地之一，其购房移民项目仍然备受青睐',
    }

  },
  {
    path: '/portugals',
    name: 'portugals',
    component: () => import('@/views/project/portugals.vue')
  },
  {
    path: '/japan',
    name: 'japan',
    component: () => import('@/views/project/japan.vue'),
    meta: {
      title: '日本经营管理签证续签条件-经营管理签证永驻要求-益汇集团',
      keywords:'日本经营管理签证，日本移民，日本签证，日本永驻',
      description:'日本经营管理签证是日本入管局为欢迎在日本创业定居的外国人士设立的长居签证。2015年4月，日本开始放宽外国人在日本创业的限制，将日本投资经营签证更名为日本经营管理签证。 在取得经营管理签证以后，即可取得日本的在留资格，加入日本的社会保险体系，未成年子女享受与国民同等待遇的公立教育。5年后可以选择入籍，10年后可以选择申请永驻。',
    }
  },
  {
    path: '/project/projectlist/property',
    name: 'property',
    component: () => import('@/views/project/projectlist/property.vue')
  },
  {
    path: '/project/projectlist/donation',
    name: 'donation',
    component: () => import('@/views/project/projectlist/donation.vue')
  },

  {
    path: '/project/projectlist/bonds',
    name: 'bonds',
    component: () => import('@/views/project/projectlist/bonds.vue')
  },

  {
    path: '/project/projectlist/investment',
    name: 'investment',
    component: () => import('@/views/project/projectlist/investment.vue')
  },
  {
    path: '/caribbean',
    name: 'caribbean',
    component: () => import('@/views/project/projectlist/caribbean.vue'),
    meta: {
      title: '海外投资技术买房护照移民出国留学-移民国家条件-美洲移民-益汇集团',
    }
  },
  {
    path: '/europe',
    name: 'europe',
    component: () => import('@/views/project/projectlist/europe.vue'),
    meta: {
      title: '海外投资技术买房护照移民出国留学-移民国家条件-欧洲移民-益汇集团',
    }
  },
  {
    path: '/school/text1',
    name: 'text1',
    component: () => import('@/views/school/text1.vue'),
    meta: {
      title: '香港留学-宣道国际学校',
    }
  },

  {
    path: '/school/text2',
    name: 'text2',
    component: () => import('@/views/school/text2.vue'),
    meta: {
      title: '香港留学-香港基督教国际学校',
    }
  },

  {
    path: '/school/text3',
    name: 'text3',
    component: () => import('@/views/school/text3.vue'),
    meta: {
      title: '香港留学-香港大学',
    }
  },

  {
    path: '/school/text4',
    name: 'text4',
    component: () => import('@/views/school/text4.vue'),
    meta: {
      title: '香港留学-香港科技大学',
    }
  },

  {
    path: '/school/text5',
    name: 'text5',
    component: () => import('@/views/school/text5.vue'),
    meta: {
      title: '香港留学-香港科技大学',
    }
  },

  {
    path: '/school/text6',
    name: 'text6',
    component: () => import('@/views/school/text6.vue'),
    meta: {
      title: '香港留学-香港理工大学',
    }
  },

  {
    path: '/school/text7',
    name: 'text7',
    component: () => import('@/views/school/text7.vue'),
    meta: {
      title: '香港留学-香港浸会大学申请',
    }
  },

  {
    path: '/school/text8',
    name: 'text8',
    component: () => import('@/views/school/text8.vue'),
    meta: {
      title: '香港留学-香港大学本科申请要求',
    }
  },



  {
    path: '/school/text9',
    name: 'text9',
    component: () => import('@/views/school/text9.vue'),
    meta: {
      title: '香港留学-2023年香港本科时间和学费全汇总',
    }
  },

  {
    path: '/school/text10',
    name: 'text10',
    component: () => import('@/views/school/text10.vue'),
    meta: {
      title: '香港留学-香港城市大学（City University of Hong Kong）',
    }
  },

  {
    path: '/school/text11',
    name: 'text11',
    component: () => import('@/views/school/text11.vue'),
    meta: {
      title: '香港留学-香港教育大学（The Education University of Hong Kong）',
    }
  },

  {
    path: '/school/text12',
    name: 'text12',
    component: () => import('@/views/school/text12.vue'),
    meta: {
      title: '香港留学-岭南大学（Lingnan University）',
    }
  },

  {
    path: '/migrate/text1',
    name: 'text1',
    component: () => import('@/views/migrate/text1.vue'),
    meta: {
      title: '益汇移民-EB-3美国移民成功案例',
    }
  },

  {
    path: '/migrate/text2',
    name: 'text2',
    component: () => import('@/views/migrate/text2.vue'),
    meta: {
      title: '益汇移民-EB-1美国移民成功案例',
    }
  },

  {
    path: '/migrate/text3',
    name: 'text3',
    component: () => import('@/views/migrate/text3.vue'),
    meta: {
      title: '益汇移民-EB-3美国移民成功案例',
    }
  },

  {
    path: '/migrate/text4',
    name: 'text4',
    component: () => import('@/views/migrate/text4.vue'),
    meta: {
      title: '益汇移民-EB-2美国移民成功案例',
    }
  },

  {
    path: '/migrate/text5',
    name: 'text5',
    component: () => import('@/views/migrate/text5.vue'),
    meta: {
      title: '益汇移民-EB-3美国移民成功案例',
    }
  },

  {
    path: '/migrate/text6',
    name: 'text6',
    component: () => import('@/views/migrate/text6.vue'),
    meta: {
      title: '益汇移民-EB-2美国移民成功案例',
    }
  },

  {
    path: '/migrate/text7',
    name: 'text7',
    component: () => import('@/views/migrate/text7.vue'),
    meta: {
      title: '益汇移民-EB-2美国移民成功案例',
    }
  },

  {
    path: '/migrate/text8',
    name: 'text8',
    component: () => import('@/views/migrate/text8.vue'),
    meta: {
      title: '益汇移民-EB-3美国移民成功案例',
    }
  },

  {
    path: '/migrate/text9',
    name: 'text9',
    component: () => import('@/views/migrate/text9.vue'),
    meta: {
      title: '益汇移民-EB-2美国移民成功案例',
    }
  },

  {
    path: '/migrate/text10',
    name: 'text10',
    component: () => import('@/views/migrate/text10.vue'),
    meta: {
      title: '益汇移民-EB-2美国移民成功案例',
    }
  },

  {
    path: '/migrate/text11',
    name: 'text11',
    component: () => import('@/views/migrate/text11.vue'),
    meta: {
      title: '益汇移民-逆袭名校！香港中文大学本科录取！',
    }
  },

  {
    path: '/migrate/text12',
    name: 'text12',
    component: () => import('@/views/migrate/text12.vue'),
    meta: {
      title: '益汇移民-香港中文大学硕士录取！',
    }
  },

  {
    path: '/migrate/text13',
    name: 'text13',
    component: () => import('@/views/migrate/text13.vue'),
    meta: {
      title: '益汇移民-香港大学录取案例',
    }
  },

  {
    path: '/migrate/text14',
    name: 'text14',
    component: () => import('@/views/migrate/text14.vue'),
    meta: {
      title: '益汇移民-香港科技大学本科录取！',
    }
  },

  {
    path: '/migrate/text15',
    name: 'text15',
    component: () => import('@/views/migrate/text15.vue'),
    meta: {
      title: '益汇移民-香港理工大学硕士录取！',
    }
  },

  {
    path: '/migrate/text16',
    name: 'text16',
    component: () => import('@/views/migrate/text16.vue'),
    meta: {
      title: '益汇移民-三所美高录取 从签约到成行一个月搞定',
    }
  },

  {
    path: '/migrate/text17',
    name: 'text17',
    component: () => import('@/views/migrate/text17.vue'),
    meta: {
      title: '益汇移民-国内普通初中顺利入读英国优质私立高中',
    }
  },

  {
    path: '/migrate/text18',
    name: 'text18',
    component: () => import('@/views/migrate/text18.vue'),
    meta: {
      title: '益汇移民-AP班的学生也能获得加拿大院校的青睐',
    }
  },


  {
    path: '/community/text1',
    name: 'text1',
    component: () => import('@/views/community/text1.vue'),
    meta: {
      title: '“普通人”该如何移民美国？',
    }
  },


  {
    path: '/community/text2',
    name: 'text2',
    component: () => import('@/views/community/text2.vue'),
    meta: {
      title: '美国EB5投资移民项目——政策详解',
    }
  },

  {
    path: '/community/text3',
    name: 'text3',
    component: () => import('@/views/community/text3.vue'),
    meta: {
      title: '“贫贱”真的不能移民吗？普通人如何移民，给自己留条退路？',
    }
  },

  {
    path: '/community/text4',
    name: 'text4',
    component: () => import('@/views/community/text4.vue'),
    meta: {
      title: '香港身份到底有什么好处？如何移民香港？',
    }
  },

  {
    path: '/community/text5',
    name: 'text5',
    component: () => import('@/views/community/text5.vue'),
    meta: {
      title: '移民奥地利的五种方法，这就你要寻找的世外桃源？',
    }
  },

  {
    path: '/community/text6',
    name: 'text6',
    component: () => import('@/views/community/text6.vue'),
    meta: {
      title: '只有一本护照有多危险？买本外国护照，最重要的是什么？',
    }
  },

  {
    path: '/community/text7',
    name: 'text7',
    component: () => import('@/views/community/text7.vue'),
    meta: {
      title: '2023年香港移居的黄金时机！附移居攻略（申请方式及条件）-益汇移民',
    }
  },

  , {
    path: '/timeinfo/youcai',
    name: 'youcai',
    component: () => import('@/views/news/timeinfo/youcai.vue')

  },
  {
    path: '/timeinfo/article1',
    name: 'article1',
    component: () => import('@/views/news/timeinfo/article1.vue')
  },

  {
    path: '/timeinfo/article2',
    name: 'article2',
    component: () => import('@/views/news/timeinfo/article2.vue')
  },

  {
    path: '/timeinfo/article3',
    name: 'article3',
    component: () => import('@/views/news/timeinfo/article3.vue')
  },

  {
    path: '/timeinfo/article4',
    name: 'article4',
    component: () => import('@/views/news/timeinfo/article4.vue')
  },

  {
    path: '/timeinfo/article5',
    name: 'article5',
    component: () => import('@/views/news/timeinfo/article5.vue')
  },

  {
    path: '/timeinfo/article6',
    name: 'article6',
    component: () => import('@/views/news/timeinfo/article6.vue')
  },

  {
    path: '/timeinfo/article7',
    name: 'article7',
    component: () => import('@/views/news/timeinfo/article7.vue')
  },

  {
    path: '/timeinfo/article8',
    name: 'article8',
    component: () => import('@/views/news/timeinfo/article8.vue')
  },

  {
    path: '/timeinfo/article9',
    name: 'article9',
    component: () => import('@/views/news/timeinfo/article9.vue')
  },

  {
    path: '/timeinfo/article10',
    name: 'article10',
    component: () => import('@/views/news/timeinfo/article10.vue')
  },

  {
    path: '/timeinfo/youcai',
    name: 'youcai',
    component: () => import('@/views/news/timeinfo/youcai.vue')

  },
  , {
    path: '/timeinfo/quxiao',
    name: 'quxiao',
    component: () => import('@/views/news/timeinfo/quxiao.vue')

  },
  , {
    path: '/timeinfo/gaocai',
    name: 'gaocai',
    component: () => import('@/views/news/timeinfo/gaocai.vue')

  },
  , {
    path: '/timeinfo/puty',
    name: 'puty',
    component: () => import('@/views/news/timeinfo/puty.vue')

  },
  , {
    path: '/timeinfo/aierl',
    name: 'aierl',
    component: () => import('@/views/news/timeinfo/aierl.vue')

  },
  , {
    path: '/timeinfo/shijie',
    name: 'shijie',
    component: () => import('@/views/news/timeinfo/shijie.vue')

  }, , {
    path: '/timeinfo/qiuxian',
    name: 'qiuxian',
    component: () => import('@/views/news/timeinfo/qiuxian.vue')

  },
  , {
    path: '/timeinfo/chenyao',
    name: 'chenyao',
    component: () => import('@/views/news/timeinfo/chenyao.vue')

  },

  {
    path: '/studyabroad/text1',
    name: 'text1',
    component: () => import('@/views/news/studyabroad/text1.vue')

  },
  {
    path: '/studyabroad/text2',
    name: 'text2',
    component: () => import('@/views/news/studyabroad/text2.vue')

  },

  {
    path: '/studyabroad/text3',
    name: 'text3',
    component: () => import('@/views/news/studyabroad/text3.vue')

  },
  {
    path: '/studyabroad/text4',
    name: 'text4',
    component: () => import('@/views/news/studyabroad/text4.vue')

  },
  {
    path: '/studyabroad/text5',
    name: 'text5',
    component: () => import('@/views/news/studyabroad/text5.vue')

  },

  {
    path: '/studyabroad/text6',
    name: 'text6',
    component: () => import('@/views/news/studyabroad/text6.vue')

  },

  {
    path: '/studyabroad/text7',
    name: 'text7',
    component: () => import('@/views/news/studyabroad/text7.vue')

  },

  {
    path: '/studyabroad/text8',
    name: 'text8',
    component: () => import('@/views/news/studyabroad/text8.vue')

  },
  {
    path: '/studyabroad/text9',
    name: 'text9',
    component: () => import('@/views/news/studyabroad/text9.vue')

  },

  {
    path: '/studyabroad/text10',
    name: 'text10',
    component: () => import('@/views/news/studyabroad/text10.vue')

  },

  {
    path: '/studyabroad/text11',
    name: 'text11',
    component: () => import('@/views/news/studyabroad/text11.vue')

  },
  {
    path: '/studyabroad/text12',
    name: 'text12',
    component: () => import('@/views/news/studyabroad/text12.vue')

  },
  {
    path: '/studyabroad/text13',
    name: 'text13',
    component: () => import('@/views/news/studyabroad/text13.vue')

  },

  {
    path: '/studyabroad/text14',
    name: 'text14',
    component: () => import('@/views/news/studyabroad/text14.vue')

  },

  {
    path: '/studyabroad/text15',
    name: 'text15',
    component: () => import('@/views/news/studyabroad/text15.vue')

  },
  {
    path: '/studyabroad/text16',
    name: 'text16',
    component: () => import('@/views/news/studyabroad/text16.vue')

  },

  {
    path: '/studyabroad/text17',
    name: 'text17',
    component: () => import('@/views/news/studyabroad/text17.vue')

  },

  , {
    path: '/introduction/shenf',
    name: 'shenf',
    component: () => import('@/views/news/introduction/shenf.vue')

  },
  , {
    path: '/introduction/paiqian',
    name: 'paiqian',
    component: () => import('@/views/news/introduction/paiqian.vue')

  },
  , {
    path: '/introduction/loudong',
    name: 'loudong',
    component: () => import('@/views/news/introduction/loudong.vue')

  }, , {
    path: '/introduction/zuikuai',
    name: 'zuikuai',
    component: () => import('@/views/news/introduction/zuikuai.vue')

  }, , {
    path: '/introduction/manzu',
    name: 'manzu',
    component: () => import('@/views/news/introduction/manzu.vue')

  },
  , {
    path: '/lectures/neidi',
    name: 'manzu',
    component: () => import('@/views/news/lectures/neidi.vue')

  }, {
    path: '/lectures/yiwen',
    name: 'yiwen',
    component: () => import('@/views/news/lectures/yiwen.vue')

  }, {
    path: '/lectures/feiyong',
    name: 'feiyong',
    component: () => import('@/views/news/lectures/feiyong.vue')

  }, {
    path: '/lectures/fandai',
    name: 'fandai',
    component: () => import('@/views/news/lectures/fandai.vue')

  },
  {
    path: '/lectures/qianli',
    name: 'qianli',
    component: () => import('@/views/news/lectures/qianli.vue')

  },
  {
    path: '/lectures/zinv',
    name: 'zinv',
    component: () => import('@/views/news/lectures/zinv.vue')

  },
  {
    path: '/area/hongKong',
    name: 'hongKong',
    component: () => import('@/views/area/hongKong.vue')

  },
  {
    path: '/area/xinjiap',
    name: 'xinjiap',
    component: () => import('@/views/area/xinjiap.vue')

  },
  {
    path: '/area/shenjic',
    name: 'shenjic',
    component: () => import('@/views/area/shenjic.vue')

  },
  {
    path: '/area/meiguo',
    name: 'meiguo',
    component: () => import('@/views/area/meiguo.vue')

  },
  {
    path: '/area/tuerq',
    name: 'tuerq',
    component: () => import('@/views/area/tuerq.vue')

  },
  {
    path: '/area/aodaliy',
    name: 'aodaliy',
    component: () => import('@/views/area/aodaliy.vue')

  },

  {
    path: '/abroad/america',
    name: 'america',
    component: () => import('@/views/abroad/america.vue')

  },

  {
    path: '/abroad/britain',
    name: 'britain',
    component: () => import('@/views/abroad/britain.vue')

  },

  {
    path: '/abroad/germany',
    name: 'germany',
    component: () => import('@/views/abroad/germany.vue')

  },

  {
    path: '/abroad/portugal',
    name: 'portugal',
    component: () => import('@/views/abroad/portugal.vue')

  },
  {
    path: '/abroad/canada',
    name: 'canada',
    component: () => import('@/views/abroad/canada.vue')
  },
  {
    path: '/abroad/australia',
    name: 'australia',
    component: () => import('@/views/abroad/australia.vue')
  },
  {
    path: '/abroad/greece',
    name: 'greece',
    component: () => import('@/views/abroad/greece.vue')
  },
  {
    path: '/abroad/spain',
    name: 'spain',
    component: () => import('@/views/abroad/spain.vue')
  },

  {
    path: '/university/case',
    name: 'case',
    component: () => import('@/views/university/case.vue'),
    meta:{
      title:'香港留学成功案例'
    }
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

// 后置守卫
router.afterEach((to, from) => {
  const id = to.query.id;
  if (id) {
    const el = document.querySelector(id);
    if (el) {
      el.scrollIntoView();
    }
  }
});

export default router
