
<template>
    <div class="banner">
        <img src="@/images/xw.jpg" alt="">
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: "banner",
    components: { router },
    data() {
        return {

        }
    },
    created() {

    },
    // 时间执行
    methods: {

    },
}
</script>

<style lang="less" scoped>
.banner {
    width: 100%;

    img {
        width: 100%;
    }
}
</style>