

<template>
  <div id="app">
    <!-- 导航 -->
    <toubu />
    <!-- banner -->
    <div v-if="nos == 1">
      <Bannner />
    </div>
    <!-- 内容 -->
    <router-view />
    
    <!-- 底部 -->
    <buttom />
  </div>
</template>

<script>
import toubu from '@/components/head.vue'
import buttom from '@/components/bottom.vue'
import Bannner from '@/components/banner.vue'
import kefu from '@/js/index'
// import Tex from '@/views/news/app.vue'
// import router from './router'; 
export default {
  name: "head",
  components: {
    toubu,
    buttom,
    Bannner,
    // kefu,
  },
  data(){
    return {
      nos : 0,
    };
  },
  created() {
  },

  updated() {
    if (this.$route.path.includes('/timeinfo') || this.$route.path.includes('/introduction') || this.$route.path.includes('/lectures')) {
      this.nos = 1
    }else{
      this.nos = 0
    }
  },
}
</script>

<style lang="less">
  #head{
    width: 100%;
    overflow: hidden;
  }
</style>
