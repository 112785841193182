(function(a, b, c, d) {
  let s = b.createElement("script");
  s.async = true;
  s.src = c+"/static/js/warbler-front.js";
  s.onload = s.onreadystatechange = function () {
      if (!this.readyState || this.readyState == "loaded" || this.readyState == "complete") d(c);
  };b.head.appendChild(s);
})(window, document,"https://www.yingyuchat.com",function(u){
   WARBLER.init({ 
     WARBLER_URL:u,
     WARBLER_KEFU_ID:"yihui123",
     WARBLER_ENT:"5168",
     WARBLER_SIZE:"400px 560px",
     WARBLERLANG:"cn" 
   })
});