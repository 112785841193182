<template>
    <div class="fabox">
        <!-- 走马灯 -->
        <div class="lantern">
            <div class="block">
                <el-carousel trigger="click" height="730px">
                    <el-carousel-item v-for="item, index in imgs" :key="index">
                        <!-- <img src="@/images/banner1.png" alt=""> -->
                        <img style="width: 100%; height: 100%;" :src="item.url" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <!-- 热门地区 -->
        <div class="region">
            <div class="w region_y">

                <!-- @/images/areas.png -->
                <img src="@/images/areas.png" alt="">
            </div>

            <div class="w region_tes">
                <p>热门地区</p>
            </div>

            <!-- 地区选择 -->
            <div class="w region_xz">
                <!-- 点击图片 -->
                <div class="clicktu" @click.stop="toleft()">
                    <img src="@/images/zhizhen.png" alt="">
                </div>

                <!-- 隐藏盒子 -->
                <div class="bosy">
                    <div class="region_xz_bg" :style="{ 'left': position + 'px' }">
                        <div class="region_hz" @click.stop="styledq(index)" :class="sytlenum == index ? 'dq' : ''"
                            v-for="item, index in areas" :key="index" style="width: 205.5px;">
                            <div>
                                <img :src="item.url" alt="">
                            </div>
                            <div>
                                <p>{{ item.name }}</p>
                                <p>{{ item.namey }}</p>
                            </div>
                        </div>

                    </div>

                </div>

                <!-- 点击图片 -->
                <div class="clicktu2" @click.stop="toright()">
                    <img src="@/images/r.png" alt="">
                </div>
            </div>

            <!-- 新闻显示图文 -->
            <div class="w imageText">
                <div class="imaget">
                    <img v-for="item, index in imgsto" :key="index" :src="item.url" v-if="sytlenum == index"
                        @click.stop="jump(item.router)">
                </div>

                <div class="text">
                    <div class="text_tba">
                        <div :class="infotab == 1 ? 'hovers' : ''" @click.stop="inofst(1)">移民资讯</div>
                        <div :class="infotab == 2 ? 'hovers' : ''" @click.stop="inofst(2)">移民攻略</div>
                        <div>MORE+</div>
                    </div>

                    <div v-if="infotab == 1" class="newtba">
                        <div>
                            <img src="@/images/zc.jpg" alt="">
                        </div>
                        <!-- <div @click.stop="jump('https://view.inews.qq.com/k/20230214A068BI00?web_channel=wap&openApp=false')">
                            香港人才优选计划2023年最新政策！进入2023年后香港优才、香港专才、香港留学相关政策都有了非常非常多的改变。
                        </div> -->

                        <div @click.stop="jump('#/timeinfo/youcai')">
                            香港人才优选计划2023年最新政策！进入2023年后香港优才、香港专才、香港留学相关政策都有了非常非常多的改变。
                        </div>
                    </div>

                    <div v-if="infotab == 2" class="newtba">
                        <div>
                            <img src="@/images/gl.jpg" alt="">
                        </div>
                        <div @click.stop="jump('#/timeinfo/quxiao')">
                            2023年起，取消香港优才计划名额限制，便宜了哪些人？
                        </div>
                    </div>

                    <div class="news_content">
                        <div class="news_tes" v-for="item, index in test" :key="index">
                            <img :src="item.urld" alt="">
                            <img v-if="index == 0" :src="item.urlh" alt="">
                            <span @click.stop="jump(item.url)">
                                {{ item.tes }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 项目 -->
        <div class="hot_items">
            <div class="w region_y">
                <img src="@/images/xm.png" alt="">
            </div>

            <div class="w region_tes">
                <p>热门移民项目</p>
            </div>

            <div class="hot_tab">
                <div class="w ">
                    <div class="hot_tab_box">
                        <div @click.stop="iteminoftnum(1)" :class="iteminoft == 1 ? 'itemsfo' : ''">推荐项目 <img
                                v-if="iteminoft == 1" src="@/images/jt.png" alt=""></div>
                        <p>/</p>
                        <div @click.stop="iteminoftnum(2)" :class="iteminoft == 2 ? 'itemsfo' : ''">投资移民 <img
                                v-if="iteminoft == 2" src="@/images/jt.png" alt=""></div>
                        <p>/</p>
                        <div @click.stop="iteminoftnum(3)" :class="iteminoft == 3 ? 'itemsfo' : ''">购房移民 <img
                                v-if="iteminoft == 3" src="@/images/jt.png" alt=""></div>
                    </div>

                </div>

                <div v-if="iteminoft == 1" class="items_test w">
                    <div @click.stop="jump(item.url)" class="items_testnr" v-for="item, index in items" :key="index">
                        <div>
                            <img :src="item.imgto" alt="">
                        </div>

                        <div class="test_wneb_box">
                            <div class="test_wneb">
                                <h2>{{ item.h1 }}</h2>
                                <p>{{ item.h2 }}</p>
                            </div>

                            <div class="test_wnebs">
                                <img :src="item.imgsui" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="iteminoft == 2" class="items_test w">
                    <div @click.stop="jump(item.url)" class="items_testnr" v-for="item, index in items" :key="index"
                        v-if="index == 2 || index == 3 || index == 4">
                        <div>
                            <img :src="item.imgto" alt="">
                        </div>

                        <div class="test_wneb_box">
                            <div class="test_wneb">
                                <h2>{{ item.h1 }}</h2>
                                <p>{{ item.h2 }}</p>
                            </div>

                            <div class="test_wnebs">
                                <img :src="item.imgsui" alt="">
                            </div>
                        </div>
                    </div>
                </div>


                <div v-if="iteminoft == 3" class="items_test w">
                    <div @click.stop="jump(item.url)" class="items_testnr" v-for="item, index in items" :key="index"
                        v-if="index == 3 || index == 5">
                        <div>
                            <img :src="item.imgto" alt="">
                        </div>

                        <div class="test_wneb_box">
                            <div class="test_wneb">
                                <h2>{{ item.h1 }}</h2>
                                <p>{{ item.h2 }}</p>
                            </div>
                            <div class="test_wnebs">
                                <img :src="item.imgsui" alt="">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- 业务范畴 -->
        <div class="category">
            <div class="category_yewu w">
                <div class="h2_img">
                    <h2>业务范畴</h2>
                    <img src="@/images/x.png" alt="">
                </div>

                <ul class="ul1">
                    <li><img src="@/images/sfxq.png" alt=""></li>
                    <li @click="jump('#/talents')"> <router-link to="">香港优才</router-link> </li>
                    <li @click="jump('#/specialists')"> <router-link to="">香港专才</router-link> </li>
                    <li @click="jump('#/europe')"> <router-link to="">欧美移民</router-link> </li>
                    <li @click="jump('#/caribbean')"> <router-link to="">北美移民</router-link> </li>
                    <li @click="jump('#/japan')"> <router-link to="">日本移民</router-link> </li>
                    <li @click="jump('/#/country')"> <router-link to="">移民国家</router-link> </li>
                    <li @click="jump('#/passport')"> <router-link to="">护照办理</router-link> </li>
                </ul>

                <ul class="ul2">
                    <li><img src="@/images/jygg.png" alt=""></li>
                    <li @click="jump('#/hongKong')"> <router-link to="">香港留学</router-link> </li>
                    <!-- <li @click="jump('#/hongKong')"> <router-link to="">新加坡留学</router-link> </li> -->
                    <li @click="jump('#/overseas')"> <router-link to="">国外留学</router-link> </li>
                </ul>

                <el-button plain
                    @click="jump('https://www.yingyuchat.com/chatIndex?kefu_id=yihui123&ent_id=5168&lang=cn')">其他项目</el-button>
            </div>
        </div>

        <!-- 专业团队 -->
        <div class="professional">
            <div class="w region_y">
                <img src="@/images/team.png" alt="">
            </div>

            <div class="w region_tes">
                <p>专业团队</p>
            </div>

            <div class="professional_teb" @mouseover="pause" @mouseleave="resume"
                @click="jump('https://www.yingyuchat.com/chatIndex?kefu_id=yihui123&ent_id=5168&lang=cn')">
                <div class="w" style="overflow: hidden;">
                    <div class="tabs">
                        <div class="tabs_hehuo" v-for="(image, index) in images" :key="index">
                            <img :src="image.src" :alt="image.alt" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 新闻资讯 -->
        <div class="news">
            <div class="w ">
                <div class="textto">
                    <div class="text_tba">
                        <div>移民资讯</div>
                        <div>MORE+</div>
                    </div>

                    <div class="newtba">
                        <div>
                            <img src="@/images/zc.jpg" alt="">
                        </div>
                        <div @click.stop="jump('#/timeinfo/youcai')">
                            香港优才”全称“优秀人才入境计划”,是香港政府20 06年开始实施的一项设有配额的移民吸纳计划。
                        </div>
                    </div>

                    <div class="news_content">
                        <div class="news_tes" v-for="item, index in test" :key="index" @click.stop="jump(item.url)">
                            <img :src="item.urld" alt="">
                            <img v-if="index == 0" :src="item.urlh" alt="">
                            {{ item.tes }}
                        </div>


                    </div>
                </div>

                <div class="textto">
                    <div class="text_tba">
                        <div>移民攻略</div>
                        <div>MORE+</div>
                    </div>

                    <div class="newtba">
                        <div>
                            <img src="@/images/gl.jpg" alt="">
                        </div>
                        <div @click.stop="jump('#/timeinfo/quxiao')">
                            2023年起，取消香港优才计划名额限制，便宜了哪些人？
                        </div>
                    </div>

                    <div class="news_content">
                        <div class="news_tes" v-for="item, index in strategy" :key="index" @click.stop="jump(item.url)">
                            <img :src="item.urld" alt="">
                            <img v-if="index == 0" :src="item.urlh" alt="">
                            {{ item.tes }}
                        </div>
                    </div>
                </div>

                <div class="textto">
                    <div class="text_tba">
                        <div>移民讲座</div>
                        <div>MORE+</div>
                    </div>

                    <div class="newtba">
                        <div>
                            <img src="@/images/sz.jpg" alt="">
                        </div>
                        <div @click.stop="jump('#/lectures/neidi')">
                            投资移民首先排除内地人，香港政府怎么想的？
                        </div>
                    </div>

                    <div class="news_content">
                        <div class="news_tes" v-for="item, index in lectures" :key="index" @click.stop="jump(item.url)">
                            <img :src="item.urld" alt="">
                            <img v-if="index == 0" :src="item.urlh" alt="">
                            {{ item.tes }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 成功案例 -->
        <div class="case">
            <div class="w2">
                <div class="region_y">
                    <img src="@/images/cases.png" alt="">
                </div>

                <div class="region_tes">
                    <p>成功案例</p>
                </div>

                <div class="case_tes" @click="jump('#/success')">


                    <div class="fud2">
                        <img src="@/images/xz.png" alt="">
                    </div>

                    <div class="fud3">
                        <img src="@/images/xz.png" alt="">
                    </div>

                    <!-- <div class="hezi">
                        <img src="@/images/zhizhen.png" alt="">
                    </div> -->

                    <div class="cses_content">
                        <div class="fud">
                            <img src="@/images/xz.png" alt="">
                        </div>

                        <div class="cses_content1">
                            <img src="@/images/fj.png" alt="">
                        </div>

                        <div class="anliwenb">
                            <div class="rbor">
                                <h2>02</h2>
                                <p>23.02</p>
                            </div>

                            <div class="lbor">
                                益汇客户某先生谈移民感触：收获远比付出 选择益汇，移民+收益一箭双雕
                            </div>
                        </div>
                    </div>

                    <div class="cses_content">
                        <div class="fud">
                            <img src="@/images/xz.png" alt="">
                        </div>

                        <div class="cses_content1">
                            <img src="@/images/fja.jpg" alt="">
                        </div>

                        <div class="anliwenb">
                            <div class="rbor">
                                <h2>11</h2>
                                <p>22.11</p>
                            </div>

                            <div class="lbor">
                                益汇客户对益汇的致辞：益汇移民办事效率快，对客户需求抓的很准
                            </div>
                        </div>
                    </div>

                    <div class="cses_content">
                        <div class="fud">
                            <img src="@/images/xz.png" alt="">
                        </div>

                        <div class="cses_content1">
                            <img src="@/images/fjb.jpg" alt="">
                        </div>

                        <div class="anliwenb">
                            <div class="rbor">
                                <h2>10</h2>
                                <p>22.10</p>
                            </div>

                            <div class="lbor">
                                益汇客户对益汇的致辞：益汇移民效率很快，让我们一家全都移民成功
                            </div>
                        </div>
                    </div>

                    <!-- <div class="hezi">
                        <img src="@/images/r.png" alt="">
                    </div> -->
                </div>

            </div>
        </div>

        <!-- 公司地址 -->
        <div class="companyAddress">
            <div class="dizhineir">
                <div>
                    <img src="@/images/add2.png" alt="">
                </div>

                <div class="neadd">
                    扎根深圳，依托香港，服务内地，面向世界
                </div>

                <div style="margin-top: 27px;">
                    <img src="@/images/hax.jpg" alt="">
                </div>

                <div class="addqjiehuan">
                    <ul>
                        <li @click.stop="clickcontact(1)" :class="contact == 1 ? 'list' : ''">加入我们</li>
                        <!-- <li @click.stop="clickcontact(2)" :class="contact == 2 ? 'list' : ''">加入我们</li> -->
                        <!-- <li></li> -->
                    </ul>

                    <div v-if="contact == 1" class="contenDetails">
                        <div>
                            <!-- 联系我们 -->
                        </div>

                        <div>
                            <img src=" " alt="">
                        </div>

                        <div>
                            地址：深圳南山区卓越前海壹号B座2303
                        </div>

                        <div>
                            咨询电话：0755-86967009 / 136 - 8492 - 6668
                        </div>

                        <div>
                            <!-- <img src="@/images/er.png" alt=""> -->
                        </div>

                        <div>
                            <!-- <img src="@/images/gd.png" alt=""> -->
                        </div>
                    </div>

                    <div v-if="contact == 2" class="contenDetails">
                        <div>
                            加入我们
                        </div>

                        <div>
                            <img src=" " alt="">
                        </div>

                        <div>
                            地址：深圳南山区卓越前海壹号B座2303
                        </div>

                        <div>
                            咨询电话：0755-86967009 / 136 - 8492 - 6668
                        </div>

                        <div>
                            <!-- <img src="@/images/er.png" alt=""> -->
                        </div>

                        <div>
                            <!-- <img src="@/images/gd.png" alt=""> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';

export default {
    name: "home",
    data() {
        return {
            // 联系
            contact: 1,

            // 团队
            team: 1,

            // 资讯
            infotab: 1,
            // 项目
            iteminoft: 1,
            // 判断数字
            num: 0,
            getnums: 0,
            // 样式选择
            sytlenum: 0,
            position: 0,
            // 公司团队
            images: [
                { src: require(`@/images/yihuicep.jpg`), alt: "" },
                // { src: require(`@/images/youcaiyim.jpg`), alt: "" },
                { src: require(`@/images/yu.png`), alt: "" },
                { src: require(`@/images/kk.png`), alt: "" },
                { src: require(`@/images/lin.png`), alt: "" },
            ],
            isPaused: false,
            currentImageIndex: 0,
            //   


            // 当前路径
            imgs: [
                { url: require(`@/images/banner1.png`) },
                { url: require(`@/images/banner2.jpg`) },
                // { url: require(`@/images/banner1.png`) },
            ],
            imgsto: [
                {
                    url: require(`@/images/xg.jpg`),
                    name: "香港",
                    router: '#/area/hongKong',
                },

                {
                    url: require(`@/images/xjp.jpg`),
                    name: "新加坡",
                    router: '#/area/xinjiap',
                },
                {
                    url: require(`@/images/sjc.jpg`),
                    name: "圣基茨",
                    router: '#/area/shenjic',
                },
                {
                    url: require(`@/images/mg.jpg`),
                    name: "美国",
                    router: '#/area/meiguo',
                },
                {
                    url: require(`@/images/teq.jpg`),
                    name: "土耳其",
                    router: '#/area/tuerq',
                },
                {
                    url: require(`@/images/adly.jpg`),
                    name: "澳大利亚",
                    router: '#/area/aodaliy',
                },
                // {
                //     url: require(`@/images/xg.jpg`),
                //     name: "香港"
                // },
                // {
                //     url: require(`@/images/xg.jpg`),
                //     name: "香港"
                // },
            ],
            areas: [
                {
                    url: require(`@/images/xianggang.png`),
                    name: "香港",
                    namey: "Hong Kong",
                },

                {
                    url: require(`@/images/xin.png`),
                    name: "新加坡",
                    namey: "Singapore",
                },
                {
                    url: require(`@/images/shen.png`),
                    name: "圣基茨",
                    namey: "Saint Kitts",
                },
                {
                    url: require(`@/images/mei.png`),
                    name: "美国",
                    namey: "America",
                },
                {
                    url: require(`@/images/tu.png`),
                    name: "土耳其",
                    namey: "Turkey",
                },
                {
                    url: require(`@/images/ao.png`),
                    name: "澳大利亚",
                    namey: "Australia",
                },
                // {
                //     url: require(`@/images/xianggang.png`),
                //     name: "香港",
                //     namey: "Hong Kong",
                // }, {
                //     url: require(`@/images/xianggang.png`),
                //     name: "香港",
                //     namey: "Hong Kong",
                // },
            ],
            // 热门移民项目
            items: [
                {
                    imgto: require(`@/images/xiangmu.png`),
                    imgsui: require(`@/images/jts.png`),
                    h1: "香港优才计划",
                    h2: "港政府为吸引高技术优秀人才赴港定居的吸纳计划，提升香港竞争力。",
                    url: '#/talents',
                },
                {
                    imgto: require(`@/images/tw.jpg`),
                    imgsui: require(`@/images/jts.png`),
                    h1: "香港专才政策",
                    h2: "2023年香港专才计划移民新政策",
                    url: '#/specialists',

                },
                {
                    imgto: require(`@/images/xm.jpg`),
                    imgsui: require(`@/images/jts.png`),
                    h1: "新加坡雇主担保移民",
                    h2: "无语言学历要求,适合中小企业主,企业高管,无需排期,无需国外教育,获批率高!快至6个月可以拿到EP签证,孩子也可参加华侨联考",
                    url: '#/singapore',

                },
                {
                    imgto: require(`@/images/sjc2.jpg`),
                    imgsui: require(`@/images/jts.png`),
                    h1: "圣基茨投资移民全新政策",
                    h2: "2022年12月，圣基茨和尼维斯投资移民局最新发布一系列针对其投资移民项目的新政策，",
                    url: '#/Kitts',

                },
                {
                    imgto: require(`@/images/tu1.jpg`),
                    imgsui: require(`@/images/jts.png`),
                    h1: "美国杰出人才移民",
                    h2: "美国eb-1a的全称是Alien of Extraordinary Ability，也就是杰出人才移民，不需要大额投资，也不需要您为雇主工作，那么美国为申请人发放绿卡的核心条件就在于，申请人本人必须足够杰出。",
                    url: '#/americahome',

                },
                {
                    imgto: require(`@/images/ym4.jpg`),
                    imgsui: require(`@/images/jts.png`),
                    h1: "格林纳达快速护照项目",
                    h2: "格林纳达护照移民2023年最新政策条件费用办理详解",
                    url: '#/grenada',

                },
            ],
            // 文章数据
            test: [
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "明白香港身份的10大优势，选择合适的渠道,移民很容易",
                    url: '#/timeinfo/article10',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "全新！优才计划大盘点！收藏这一篇就够了！！！",
                    url: '#/timeinfo/article1',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "香港人才优选计划2023年最新政策！",
                    url: '#/timeinfo/youcai',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "中国撑腰，香港“暴力收割”全球人才、资本",
                    url: '#/timeinfo/chenyao',

                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "香港优才申请失败后，我是如何拿到香港身份赴港工作的？",
                    url: 'https://mp.weixin.qq.com/s/N9lPi7W4Pn-s4KjhqP24Gg'

                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "2023年起，取消香港优才计划名额限制，便宜了哪些人？",
                    url: '#/timeinfo/quxiao',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "香港高才获批率高达95%",
                    url: '#/timeinfo/gaocai',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "葡萄牙黄金签证进入倒计时",
                    url: '#/timeinfo/puty',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "突发！爱尔兰投资移民关停",
                    url: '#/timeinfo/aierl',

                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "连接世界｜PKF中国合伙人接连拜访西班牙、葡萄牙",
                    url: '#/timeinfo/shijie',

                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "求贤若渴！港府再次完善优才政策加大力度引入人才",
                    url: '#/timeinfo/qiuxian',

                },
            ],

            // 移民攻略
            strategy: [
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "高才至今1.7万人获批！",
                    url: '#/timeinfo/article5',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "2023年香港移民的六种方式，投资移民有望重启？",
                    url: '#/timeinfo/article4',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "移民香港，你要知道的几条主要渠道",
                    url: '#/timeinfo/article3',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "最快一天就能拿香港身份，什么方法这么快？",
                    url: '#/introduction/zuikuai'
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "满足人才清单，就能100%获批香港优才吗？",
                    url: '#/introduction/manzu'

                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "香港专才计划申请攻略",
                    url: '#/timeinfo/article2',
                },

                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "争破头也要获得香港身份，18大优势有多香？",
                    url: '#/introduction/shenf'
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "香港给每人派钱5000！还将启动新“投资移民计划”",
                    url: '#/introduction/paiqian'
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "香港高才计划出大事了，港府取消签证，连夜修补漏洞！",
                    url: '#/introduction/loudong'
                },
            ],

            // 移民讲座
            lectures: [
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "【移民】移民香港持续大热",
                    url: '#/timeinfo/article9',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "香港扩大人才清单，由13项专业增加至51项",
                    url: '#/timeinfo/article8',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "最快4周就能获得审批？2023移民香港4大攻略！",
                    url: '#/timeinfo/article7',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "移民不移居，香港身份的19大好处。",
                    url: '#/timeinfo/article6',
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "一文看透火爆大湾区的香港DSE考试！家长必备！",
                    url: '#/lectures/yiwen'
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "港人雇菲佣都可以减税，香港算不算中产友好型社会？",
                    url: '#/lectures/feiyong'
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "内地房贷到延长至95岁，香港却很少有人还不起房贷！",
                    url: '#/lectures/fandai'
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "史无前例！香港将开办第一所内地课程学校！你会让孩子入读",
                    url: '#/lectures/qianli'
                },
                {
                    urld: require(`@/images/d.png`),
                    urlh: require(`@/images/huo.png`),
                    tes: "靠申请香港身份规划子女教育，哪种渠道最简单？",
                    url: '#/lectures/zinv'
                },

            ],
        };
    },
    created() {
        this.getnums = this.areas.length;
    },
    beforeCreate() {
        // console.log(this.areas.length,"beforeCreate");
    },
    methods: {
        jump(index) {
            window.open(index, '_blank')
        },

        // 团队资源
        startSlide() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            this.intervalId = setInterval(() => {
                this.currentImageIndex++;
                if (this.currentImageIndex === this.images.length) {
                    this.currentImageIndex = 0;
                }
                // 添加过渡效果
                const slideDistance = this.currentImageIndex * -306;
                this.$nextTick(() => {
                    // this.$refs.tabs.style.left = `${slideDistance}px`;
                });
            }, this.intervalTime);
        },
        pause() {
            this.isPaused = true;
        },
        resume() {
            this.isPaused = false;
        },
        // 


        // 联系我们
        clickcontact(index) {
            this.contact = index
        },

        // 团队
        teams(index) {
            this.team = index;
        },

        // 资讯
        inofst(index) {
            this.infotab = index;
        },

        // 项目
        iteminoftnum(index) {
            this.iteminoft = index
        },

        styledq(index) {
            this.sytlenum = index;
        },

        toleft() {
            // console.log(this.areas.length,);
            if (this.num != 0) {
                this.getnums = this.getnums + 1
                this.num = this.num - 1
                this.position = this.position + 206;
                // console.log(this.num, '往左');
                // this.position = '-'+
            }

        },
        toright() {
            if (this.areas.length > 6 && this.getnums - 1 >= 6) {
                this.getnums = this.getnums - 1,
                    console.log(this.getnums);
                this.num = this.num + 1
                this.position = this.position - 206;
                // console.log(this.num, '右边');
            }
        }
    },

    mounted() {
        this.startSlide();
        setInterval(() => {
            if (!this.isPaused) {
                let firstImage = this.images.shift();
                this.images.push(firstImage);
            }
        }, 2000); // set the interval based on your requirement
    },
    destroyed() {
        clearInterval(this.intervalId);
    },
    components: { router }
}
</script>

<style lang="less" scoped>
.w {
    width: 1256px;
    height: 100%;
    margin: 0 auto;
    // background-color: aqua;
}

.fabox {
    .lantern {
        .img {
            width: 100% !important;
            height: 100%;
            object-fit: cover !important;
        }

        .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 150px;
            margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
        }

        .el-carousel__item:nth-child(2n+1) {
            background-color: #d3dce6;
        }
    }

    .region {
        width: 100%;
        height: 750px;
        overflow: hidden;

        .region_y {
            height: 20px;
            text-align: center;
            margin-top: 85px;
        }

        .region_tes {
            height: 25px;
            text-align: center;
            margin-top: 14px;

            p {
                color: #020202;
                font-size: 25px;
            }
        }

        .region_xz {
            height: 92px;
            margin-top: 52px;
            position: relative;

            .clicktu {
                position: absolute;
                left: -70px;
                z-index: 999;
                top: 24px;
            }

            .clicktu2 {
                position: absolute;
                right: -70px;
                z-index: 999;
                top: 24px;
            }

            .bosy {
                width: 1256px;
                height: 92px;
                overflow: hidden;
                position: relative;

                .region_xz_bg {
                    height: 92px;
                    display: flex;
                    position: absolute;

                    .region_hz {
                        width: 210px;
                        border: 2px solid #e0e0e0;
                        display: flex;
                        overflow: hidden;
                        padding: 17px 0 17px;

                        div:nth-child(1) {
                            margin-left: 35px;
                        }

                        div:nth-child(2) {
                            margin-left: 19px;
                        }

                        div {
                            p:nth-child(1) {
                                color: #333333;
                                font-size: 16px;
                                margin-top: 5px;
                            }

                            p:nth-child(2) {
                                color: #333;
                                font-size: 13px;
                                margin-top: 10px;
                            }
                        }
                    }

                    .region_hz:hover {
                        border: 2px solid #481506;
                        cursor: pointer;
                        transition: all 0.3s linear 0s;
                    }
                }

            }

        }

        .imageText {
            height: 402px;
            margin-top: 28px;
            display: flex;
            justify-content: space-between;

            .imaget {
                width: 719px;
                height: 100%;
                // background-color: #481506;
                overflow: hidden;
            }

            .text {
                width: 508px;
                height: 100%;

                .text_tba {
                    width: 100%;
                    height: 38px;
                    border-bottom: 2px solid #e0e0e0;
                    display: flex;

                    div:nth-child(1) {
                        font-size: 21px;
                        color: #666666;
                        cursor: pointer;
                    }

                    div:nth-child(2) {
                        font-size: 21px;
                        color: #666666;
                        cursor: pointer;
                        margin-left: 41px;

                    }

                    div:nth-child(3) {
                        font-size: 15px;
                        color: #666666;
                        margin-left: 244px;
                    }

                    div:nth-child(-n+2):hover {
                        color: #9c8979;
                        border-bottom: 2px solid #9c8979;
                        transition: all 0.3s linear 0s;
                    }

                    .hovers {
                        color: #9c8979 !important;
                        border-bottom: 2px solid #9c8979;
                    }
                }

                .newtba {
                    display: flex;
                    font-size: 16px;
                    color: #333333;
                    border-bottom: 1px dotted #9c8979;
                    padding: 20px 0;
                    cursor: pointer;

                    div:nth-child(2) {
                        width: 382px;
                        height: 45px;
                        line-height: 23px;
                        margin: 13px 0 0 20px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        /* 根据业务需求设置第几行显示省略号 */
                        overflow: hidden;
                        // a{
                        //     color: #333333;
                        // }
                        // a:hover{
                        //     color:  #9c8979;
                        // }
                    }

                    div:nth-child(2):hover {
                        color: #9c8979;
                    }
                }

                .news_content {
                    height: 218px;
                    margin-top: 21px;
                    overflow-y: scroll;

                    .news_tes {
                        color: #666;
                        font-size: 13px;

                        span {
                            color: #666;
                            font-size: 13px;
                        }

                        span:hover {
                            color: #9c8979;
                            cursor: pointer;
                        }

                        // span{
                        //     color: #666;
                        //     font-size: 13px;
                        // }
                        img {
                            vertical-align: middle;
                        }

                        img:nth-child(1) {
                            margin-right: 17px;
                        }

                        img:nth-child(2) {
                            margin-right: 11px;
                        }
                    }

                    .news_tes:nth-of-type(n+2) {
                        margin-top: 15px;
                    }

                    // .news_tes:hover {
                    //     color: #9c8979;
                    //     cursor: pointer;
                    // }
                }
            }
        }
    }

    .hot_items {
        width: 100%;
        height: 1031px;
        overflow: hidden;
        background-color: #f7f7f7;

        .region_y {
            height: 20px;
            text-align: center;
            margin-top: 85px;
        }

        .region_tes {
            height: 25px;
            text-align: center;
            margin-top: 14px;

            p {
                color: #020202;
                font-size: 25px;
            }
        }

        .hot_tab {
            width: 100%;
            border-top: 1px solid #ebebeb;
            margin-top: 42px;
            padding-top: 29px;

            .hot_tab_box {
                display: flex;
                justify-content: center;
                align-items: center;

                div {
                    padding: 0 31px;
                    font-size: 17px;
                    color: #666;
                    position: relative;

                    img {
                        position: absolute;
                        left: 50px;
                        top: -45px;
                    }
                }

                div:hover {
                    color: #a0785b;
                    cursor: pointer;
                }

                .itemsfo {
                    color: #a0785b !important;
                    cursor: pointer;
                }
            }

            .items_test {
                height: 665px;
                margin-top: 50px;
                display: flex;
                flex-wrap: wrap;
                overflow: hidden;

                .items_testnr {
                    width: 398px;
                    height: 310px;
                    background-color: #fff;
                    border-radius: 5px;
                    box-shadow: 2px 2px 5px #edeae8;
                    overflow: hidden;
                }

                .items_testnr:nth-child(2) {
                    margin-left: 30px;
                }

                .items_testnr:nth-child(3) {
                    margin-left: 30px;
                }

                .items_testnr:nth-child(n+4) {
                    margin-top: 44px;
                }

                .items_testnr:nth-child(5) {
                    margin-left: 30px;
                }

                .items_testnr:nth-child(6) {
                    margin-left: 30px;
                }

                .test_wneb_box {
                    display: flex;
                    justify-content: space-between;
                }

                .test_wnebs {
                    margin: 15px 17px 0 0;
                }

                .test_wneb {
                    width: 211px;
                    height: 41px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    margin: 22px 0 0 17px;

                    h2 {
                        font-size: 17px;
                        color: #a0785b;
                    }

                    p {
                        font-size: 13px;
                        color: #888;
                        margin-top: 11px;
                    }
                }
            }
        }
    }

    .category {
        width: 100%;
        height: 403px;
        background-image: url('@/images/bj.png');
        overflow: hidden;

        .category_yewu {
            overflow: hidden;

            .h2_img {
                margin-top: 59px;
                display: flex;

                img {
                    width: 139px;
                    height: 14px;
                    margin: 25px 0 0 29px;
                }
            }

            h2 {
                color: #fff;
                font-size: 39px;
            }

            .ul1 {
                display: flex;
                margin: 49px 0 20px 0;

                li {
                    line-height: 25px;

                    a {
                        color: #fff;
                        font-size: 16px;
                    }
                }

                li:not(:first-child) {
                    padding: 0 33px;
                }

                li:nth-child(1) {
                    margin-right: 40px;
                }
            }

            .ul2 {
                display: flex;
                padding-bottom: 64px;

                li {
                    line-height: 25px;

                    a {
                        color: #fff;
                        font-size: 16px;
                    }
                }

                li:not(:first-child) {
                    padding: 0 33px;
                }

                li:nth-child(1) {
                    margin-right: 40px;
                }
            }

            .el-button {
                background-color: rgba(85, 0, 0, 0) !important;
                color: #fff;
            }
        }
    }

    // 专业团队
    .professional {
        width: 100%;
        height: 787px;
        overflow: hidden;

        .region_y {
            height: 20px;
            text-align: center;
            margin-top: 78px;
        }

        .region_tes {
            height: 25px;
            text-align: center;
            margin-top: 14px;

            p {
                color: #020202;
                font-size: 25px;
            }
        }

        .professional_teb {
            width: 100%;
            border-top: 1px solid #ebebeb;
            margin-top: 42px;
            padding-top: 29px;

            .tabs {
                transition: left 0.5s ease-in-out;
                width: 125%;
                height: 506px;
                margin-top: 33px;
                overflow: hidden;
                display: flex;
                justify-content: space-between;

                .tabs_hehuo {
                    width: 306px;
                    height: 431px;
                    overflow: hidden;
                    border-radius: 20px;

                    img {
                        margin-top: 30px;

                        &:hover {
                            margin-top: 0px;
                            transition: all 0.3s linear 0s;
                        }
                    }
                }

                .stopSlide {
                    animation-play-state: paused;
                }
            }
        }
    }

    // 新闻资讯
    .news {
        height: 544px;
        width: 100%;
        background-color: #f7f7f7;
        overflow: hidden;

        .w {
            display: flex;
            justify-content: space-between;
        }

        .textto {
            width: 393px;
            height: 402px;
            overflow: hidden;
            // background-color: #06ff27;
            // height: 100%;
            margin-top: 82px;

            .text_tba {
                width: 100%;
                height: 38px;
                border-bottom: 2px solid #e0e0e0;
                display: flex;
                justify-content: space-between;

                div:nth-child(1) {
                    font-size: 21px;
                    color: #a0785b;
                    cursor: pointer;
                    border-bottom: 1px solid #a0785b;
                }

                div:nth-child(2) {
                    font-size: 14px;
                    color: #666;
                }

                // div:nth-child(2) {
                //     font-size: 21px;
                //     color: #666666;
                //     cursor: pointer;
                //     margin-left: 41px;

                // }

                // div:nth-child(3) {
                //     font-size: 15px;
                //     color: #666666;
                //     margin-left: 244px;
                // }

                // div:nth-child(-n+2):hover {
                //     color: #9c8979;
                //     border-bottom: 2px solid #9c8979;
                //     transition: all 0.3s linear 0s;
                // }
            }

            .newtba {
                display: flex;
                font-size: 16px;
                color: #333333;
                border-bottom: 1px dotted #9c8979;
                padding: 20px 0;
                cursor: pointer;

                div:nth-child(2) {
                    width: 382px;
                    height: 45px;
                    line-height: 23px;
                    margin: 13px 0 0 20px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    /* 根据业务需求设置第几行显示省略号 */
                    overflow: hidden;
                }

                div:nth-child(2):hover {
                    color: #9c8979;
                }
            }

            .news_content {
                height: 218px;
                margin-top: 21px;
                // overflow: hidden;
                overflow-y: scroll;

                .news_tes {
                    color: #666;
                    font-size: 13px;
                    height: 16px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;

                    // white-space: nowrap;
                    // text-overflow: ellipsis;
                    p {
                        color: #666;
                        font-size: 13px;
                    }


                    img:nth-child(1) {
                        margin-right: 17px;
                    }

                    img:nth-child(2) {
                        margin-right: 11px;
                    }
                }

                .news_tes:nth-of-type(n+2) {
                    margin-top: 15px;
                }

                .news_tes:hover {
                    color: #9c8979;
                    cursor: pointer;
                }
            }

        }
    }

    // 成功案例
    .case {
        width: 100%;
        height: 769px;
        overflow: hidden;

        .w2 {
            width: 1394px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;

            .region_y {
                height: 20px;
                text-align: center;
                margin-top: 85px;
            }

            .region_tes {
                height: 25px;
                text-align: center;
                margin-top: 14px;

                p {
                    color: #020202;
                    font-size: 25px;
                }
            }


            .case_tes {
                width: 100%;
                height: 358px;
                margin-top: 54px;
                display: flex;
                position: relative;
                justify-content: space-between;



                .fud2 {
                    display: none;
                    position: absolute;
                    left: 38px;
                    top: 0;
                    width: 397px;
                    height: 298px;
                    background-color: rgba(0, 0, 0, 0);
                    text-align: center;
                    line-height: 298px;

                    img {
                        opacity: 0;
                    }
                }

                .fud3 {
                    display: none;
                    position: absolute;
                    left: 38px;
                    top: 0;
                    width: 397px;
                    height: 298px;
                    background-color: rgba(0, 0, 0, 0);
                    text-align: center;
                    line-height: 298px;

                    img {
                        opacity: 0;
                    }
                }



                .hezi {
                    margin-top: 107px;

                    img {
                        vertical-align: middle;
                    }
                }

                .cses_content {
                    width: 398px;
                    height: 358px;
                    position: relative;

                    .fud {
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        width: 397px;
                        height: 298px;
                        background-color: rgba(0, 0, 0, 0);
                        text-align: center;
                        line-height: 298px;

                        img {
                            opacity: 0;
                        }
                    }

                    .fud:hover {
                        background-color: rgba(0, 0, 0, 0.219);

                        img {
                            opacity: 1;
                        }
                    }

                    .cses_content1 {
                        width: 100%;
                    }

                    .anliwenb {
                        display: flex;
                        margin-top: 2px;

                        .rbor {
                            text-align: center;
                            border-right: 1px solid #919191;
                            padding-right: 18px;

                            h2 {
                                font-size: 31px;
                                color: #333;
                            }

                            p {
                                font-size: 16px;
                                color: #333;
                            }
                        }

                        .lbor {
                            font-size: 16px;
                            color: #666666;
                            margin: 0 0 0 18px;
                            line-height: 24px;
                        }
                    }
                }
            }


        }
    }

    // 地址
    .companyAddress {
        width: 100%;
        height: 504px;
        background-image: url('@/images/sh.png');
        position: relative;

        .dizhineir {
            width: 1256px;
            height: 448px;
            background-image: url('@/images/diz2.jpg');
            position: absolute;
            transform: translate(-50%);
            left: 50%;
            bottom: 48px;
            box-shadow: 2px 2px 5px #0000006b;
            padding: 55px 0 0 79px;

            .neadd {
                font-size: 15px;
                color: #fff;
                margin-top: 22px;
            }

            .addqjiehuan {
                display: flex;

                ul {
                    margin-top: 22px;

                    li {
                        font-size: 22px;
                        color: #a1acb7;
                        // color: #263341;
                    }

                    li:nth-child(n+2) {
                        margin-top: 34px;
                        font-size: 22px;
                        color: #a1acb7;
                    }

                    .list {
                        color: #fff !important;
                    }

                }

                .contenDetails {
                    margin-top: 47px;
                    margin-left: 93px;

                    div:nth-child(1) {
                        font-size: 22px;
                        color: #263341;
                    }

                    div:nth-child(2) {
                        margin-top: 18px;
                    }

                    div:nth-child(3) {
                        margin-top: 22px;
                        color: #fff;
                        font-size: 16px;
                    }

                    div:nth-child(4) {
                        margin-top: 10px;
                        color: #fff;
                        font-size: 16px;
                    }

                    div:nth-child(5) {
                        margin-top: 22px;
                    }

                    div:nth-child(6) {
                        margin-top: 31px;
                    }
                }
            }
        }
    }
}


.dq {
    border: 2px solid #481506 !important;
    cursor: pointer !important;
}

// .el-carousel__indicators--outside{
//     display: none !important;
// }
</style>